const uk = {
  7000001: "сітка",
  7000002: "Грід стратегія",
  7000003: "Спот-сітка",
  7000004: "Осцилятор",
  7000005: "Покупка дешевше, продаж дорожче",
  7000006: "Циклічний арбітраж",
  7000007: "Інструмент",

  target_8: "Стратегічний рахунок",
};
export default uk;

const fr = {
  7000001: "Grille",
  7000002: "Stratégie de grille",
  7000003: "Grille Spot",
  7000004: "Oscillateur",
  7000005: "Acheter à bas prix, vendre à haut prix",
  7000006: "Arbitrage cyclique",
  7000007: "Outil",

  target_8: "Compte de stratégie",
};
export default fr;

const es = {
    7000001: "Red",
    7000002: "Estrategia de red",
    7000003: "Spot de Red",
    7000004: "Oscilador",
    7000005: "Comprar Bajo Vender Alto",
    7000006: "Arbitraje cíclico",
    7000007: "Herramienta",

    target_8: "Cuenta de estrategia",
}
export default  es

const vi = {
  7000001: "Lưới",
  7000002: "Chiến lược lưới",
  7000003: "Lưới Spot",
  7000004: "Bộ dao động",
  7000005: "Mua Thấp Bán Cao",
  7000006: "Trọng tài theo chu kỳ",
  7000007: "Công cụ",

  target_8: "Tài khoản chiến lược",
};

export default vi;

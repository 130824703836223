const zh = {
  7000001: "网格",
  7000002: "网格策略",
  7000003: "现货网格",
  7000004: "震荡行情",
  7000005: "低买高卖",
  7000006: "循环套利",
  7000007: "工具",

  target_8: "策略账户",
};
export default zh;

const ja = {
    7000001: "グリッド",
    7000002: "グリッド戦略",
    7000003: "現物グリッド",
    7000004: "変動相場",
    7000005: "安く買って高く売る",
    7000006: "循環アービトラージ",
    7000007: "ツール",

    target_8: "戦略アカウント",
}
export default  ja

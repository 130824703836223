import zhLocal from 'element-ui/lib/locale/lang/zh-CN'
import ac_zh from "./activity/ac_zh";
import zhPoer from './languagePoer/zh'
const zh = {
    400000: '学院',
    400001: '新闻',
    400002: '数据',
    400003: '视频',
    400004: '学习',
    400005: '登录',
    400006: '注册',
    400007: '钱包',
    400008: '钱包总览',
    400009: '现货主板区账户',

    400010: 'P2P账户',
    400011: '合约账户',
    400012: '钱包历史记录',
    400013: '现货主板区订单',
    400014: '合约订单',
    400015: '总览',
    400016: '身份认证',
    400017: '账户安全',
    400018: '返佣',
    400019: '偏好设置',

    400020: '卡券中心',
    400021: '退出登录',
    400022: '<em class="normal">{num}</em> 条新通知',
    400023: '清除全部',
    400024: '查看更多',
    400025: '暂无数据',
    400026: '扫描下载APP',
    400027: '更多下载选择',
    400028: '通知详情',
    400029: '知道了',

    400030: 'P2P',
    400031: '行情',
    400032: '现货',
    400033: '合约',
    400034: 'Super Start',
    400035: '该地址不存在',
    400036: '全球最具信赖的数字资产交易平台',
    400037: '关于',
    400038: '服务协议',
    400039: '隐私条款',

    400040: '公告中心',
    400041: '学习',
    400042: '新手中心',
    400043: '现货交易',
    400044: '合约交易',
    400045: '常见问题',
    400046: '服务',
    400047: '费率标准',
    400048: '交易规则',
    400049: '上币申请',

    400050: '返佣',
    400051: '联系我们',
    400052: '帮助中心',
    400053: '提交工单',
    400054: '商务联络',
    400055: '机构合作',
    400056: '语言和地区',
    400057: '汇率',
    400058: '选择语言和地区',
    400059: '选择汇率',

    400060: '划转',
    400061: 'SuperEx内部转账不收取手续费',
    400062: '从',
    400063: '到',
    400064: '交易对',
    400065: '手续费抵扣',
    400066: '全仓',
    400067: '逐仓',
    400068: '分仓',
    400069: '余额',

    400070: '币种',
    400071: '搜索',
    400072: '数量',
    400073: '可用资产',
    400074: '全部',
    400075: '确认划转',
    400076: '开通',
    400077: '下次再说',
    400078: '开通合约账户',
    400079: '您尚未开通合约账户,暂时无法进行划转',

    400080: '{timeout}秒后重发',
    400081: '发送验证码',
    400082: '所有币种',
    400083: '所有法币',
    400084: '所有状态',
    400085: '自选区',
    400086: '第三方',
    400087: '帮助指引',
    400088: '订单',
    400089: 'P2P商户中心',

    400090: 'P2P用户中心',
    400091: '收款方式',
    400092: '发布新广告',
    400093: '我的广告',
    400094: '更多',
    400095: '去申请',
    400096: '取消',
    400097: '您暂时无法发布广告',
    400098: '请先申请成为广告方',
    400099: '去认证',

    400100: '暂不认证',
    400101: '您尚未完成身份认证',
    400102: '为了您与您交易用户的账号安全,请先完成身份认证',
    400103: '设置昵称',
    400104: '请设置您的账号昵称,建议不要使用真实姓名.昵称长度2~10个字符.',
    400105: '抱歉，现在暂时无法申请成为商户。',
    400106: '设置成功',
    400107: 'SuperEx P2P的优势',
    400108: '0手续费',
    400109: '在SuperEx P2P，您可以方便快捷地交易比特币等数字货币，下单方以零手续费交易。',

    400110: '灵活的支付方式',
    400111: 'SuperEx P2P让卖家可以自由选择支付方式。',
    400112: '以更优价格交易',
    400113: 'SuperEx P2P让用户可以自主定价，选择自己偏好的价格买卖数字货币。',
    400114: '安全交易比特币等数字货币',
    400115: 'SuperEx P2P首创T+N提现机制，对商家严格管理，风控大数据筛选排查可疑交易，确保平台资金安全，用户能安全交易。',
    400116: '{amount} 成交量',
    400117: '{num} %成交率',
    400118: '价格',
    400119: '支付时效',

    400120: '卖家收款方式',
    400121: '交易条款',
    400122: '我要支付',
    400123: '我将收到',
    400124: '我要卖',
    400125: '支付方式',
    400126: '设置收款方式',
    400127: '添加收款方式',
    400128: '参考单价 {price}',
    400129: '购买',

    400130: '出售',
    400131: '为了保护资产安全，将对买入资产实行{limit}提币限制',
    400132: '了解更多',
    400133: '我知道了',
    400134: '根据您证件国和平台运营规范，请您切换到本国货币进行交易',
    400135: '购买限额: {min}-{max} {fiat}',
    400136: '出售限额: {min}-{max} {fiat}',
    400137: '广告主',
    400138: '限额/数量',
    400139: '交易',

    400140: '数量',
    400141: '限额',
    400142: '去处理',
    400143: '好的',
    400144: '开始交易前，请先完成身份认证',
    400145: '您的今日订单取消次数已超{num}次，无法继续交易，请在{time}之后再尝试',
    400146: '您的待处理订单已达上限（{num}个）。请先完成正在进行的订单。',
    400147: '该广告主待处理订单已达上限，请稍后操作或尝试其他广告。',
    400148: '您未满足广告方要求，请尝试其他广告',
    400149: '请选择',

    400150: 'P2P如何运作',
    400151: '购买数字货币',
    400152: '出售数字货币',
    400153: '下订单',
    400154: '下订单后，卖家该笔交易的资产将由 SuperEx P2P 托管。',
    400155: '向卖方付款',
    400156: '通过交易信息中的付款方式向卖家汇款。完成汇款后在 SuperEx P2P 上点击“已付款，通知卖方”',
    400157: '获取您的数字货币',
    400158: '一旦卖家确认收到款项，托管的货币将被发放给您。',
    400159: '确认付款',

    400160: '检查您帐户中的交易记录，并确保您收到买家发送的款项。',
    400161: '放行数字货币',
    400162: '确认收到款项后，在 SuperEx P2P 上向买家发放数字货币。',
    400163: '金额',
    400164: '输入金额',
    400165: '法币',
    400166: '所有支付方式',
    400167: '广告编号',
    400168: '确定',
    400169: '确定要下架广告吗？',

    400170: '1.下架广告用户将无法通过广告与您进行交易',
    400171: '2.下架广告不影响正在进行的订单',
    400172: '确认删除该广告吗？',
    400173: '编辑',
    400174: '删除',
    400175: '已上架',
    400176: '已下架',
    400177: '创建时间',
    400178: '最低限额',
    400179: '剩余量/委托量',

    400180: '委托价',
    400181: '类型',
    400182: '上架成功',
    400183: '所有类型',
    400184: '状态',
    400185: '广告编号/币种/日期',
    400186: '操作',
    400187: '根据您SuperEx身份认证的国家或地区和平台的运营规范，请您选择支持的法币进行交易',
    400188: '不满足广告方要求',
    400189: '删除成功',

    400190: '下架成功',
    400191: '发布广告',
    400192: '编辑广告',
    400193: '固定价格',
    400194: '浮动价格',
    400195: '价格浮动比例',
    400196: '请输入',
    400197: '交易价格',
    400198: '市场参考价',
    400199: '设置浮动比例可以让您以高于或低于市场实时参考价一定百分比进行买卖',

    400200: '固定价格不会随市场波动发生变化',
    400201: '交易限制',
    400202: '请输入交易数量',
    400203: '交易数量',
    400204: '最小单笔限额',
    400205: '请输入最小单笔限额',
    400206: '最大单笔限额',
    400207: '请输入最大单笔限额',
    400208: '最大',
    400209: '该广告所需手续费约 {rate}% {currency},当前可用{available} {currency}',

    400210: '付款方式',
    400211: '最多选择3个',
    400212: '暂无付款方式',
    400213: '请选择支付时效',
    400214: '请输入内容',
    400215: '交易用户条件',
    400216: '增加交易用户限制，会减少您广告的展示机会',
    400217: '完成注册',
    400218: '天后',
    400219: '立即上线',

    400220: '稍后上线',
    400221: '风险提示',
    400222: '必须输入数字',
    400223: '请选择付款方式',
    400224: '请输入浮动比例',
    400225: '请输入固定价格',
    400226: '价格已偏离市场 {num}%,继续发布将存在风险，请确认是否继续发布广告？',
    400227: '最大单笔限额需大于最小单笔限额',
    400228: '最大单笔限额不得高于{num} {fiat}',
    400229: '最小单笔限额不得低于{num} {fiat}',

    400230: '最小单笔限额需小于最大单笔限额',
    400231: '最大交易数量不得高于{num} {currency}',
    400232: '发布成功',
    400233: 'P2P交易暂不对钱包授权用户开放，请使用SuperEx账号登录后进行P2P交易',
    400234: 'DAO网络',
    400235: 'Roadmap',
    400236: '邀请好友',
    400237: '帮助中心',
    400238: '空投基地',
    400239: '主网浏览器',

    400240: '测试网浏览器',
    400241: '开发者文档',
    400242: '活动中心',
    400243: '使用 SuperEx 网站和/或维护您的帐户，即表示您同意我们更新的《使用条款》、《隐私政策》和 《Cookie 政策》。通过点击“X”，您确认已阅读并同意上述条款和政策。',
    400244: 'Buy Tether | Get USDT | SuperEx P2P',
    400245: '任务中心',
    400246: '货币',
    400247: '收到',
    400248: '支出',
    400249: '可用余额:',

    400250: '支付',
    400251: '订单详细信息',
    400252: '金额(含交易费用)',
    400253: '到达时间',
    400254: '约{time}分钟',
    400255: '提示',
    400256: '1.您将离开SUPEREX并进入第三方{name}网页以完成您的订单。',
    400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: '我已阅读并同意免责声明',
    400261: '如何在SuperEx上购买加密货币？',
    400262: '如何在SuperEx上出售加密货币？',
    400263: '下达加密货币订单',
    400264: '选择您想要支付的法定货币和您想要购买的加密货币。输入您想要花费的金额。',
    400265: '验证您的身份',
    400266: '在SuperEx和支付提供商上完成KYC验证，完成支付。',
    400267: '检查您的订单详细信息',
    400268: '检查您的存款历史记录以查看订单状态。',
    400269: '1. 我可以使用哪些支付方式购买加密货币？',

    400270: 'SuperEx目前支持VISA、Mastercard、Apple Pay、Google Pay等多种支付方式。支持的第三方服务提供商包括 Mercuryo、alchemypay。',
    400271: '2. 我可以购买哪些加密货币？',
    400272: 'SuperEx supports mainstream cryptocurrencies such as BTC, ETH, USDT, LTC, EOS, XRP, BCH, ETC, and TRX.',
    400273: '3. SuperEx是否监控支持的第三方服务提供商的质量和价格？',
    400274: 'SuperEx精心挑选了一批具有值得信赖的行业信誉、知名度、安全性的服务提供商，为用户提供流程化的服务。',
    400275: '4. 购买加密货币需要支付哪些费用？',
    400276: '您向第三方服务提供商支付费用和链上转账费用。SuperEx不收取任何费用。',
    400277: '5. 付款后需要多长时间才能收到加密货币？',
    400278: '当您在第三方服务商平台完成支付后，您的加密货币将在大约2-10分钟内存入您的SuperEx现货账户。',
    400279: '6、购买过程中遇到问题怎么办？',

    400280: '如果您在交易过程中遇到任何问题，请联系客户支持。如果您付款完成后仍未收到加密货币，请联系第三方服务提供商检查 订单详细信息（这通常是最有效的方法）。由于您当前所在地区的IP或某些政策原因，您必须选择人工验证。',
    400281: '单笔限额:',
    400282: '请输入数量',
    400283: '支付渠道',
    400284: '获得',
    400285: '余额不足',
    400286: "您将离开SuperEx并跳转到<span>{name}</span>，这是一家有执照的金融机构，作为第三方提供银行卡支付能力。使用中发生任何风险事件均由<span>{name}</span>承担。在使用<span>{name}</span>服务之前，请先阅读并同意其 <span @click='window.open('www.baidu.com')'>服务协议</span> 如果您对信用卡押金有任何疑问，请联系 <a>{name}客服</a>以了解更多详情。",
    400287: '免责声明',
    400289: '可用现货账户',
    400290: '确认',
    400291: 'ET 专区',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: '快速买币',

    400295:'请打开safari浏览器使用Applepay',
    400288: '金融',

    400292:'金融账户',



    410000:'SCS 空投',
    410001:'代理商',

    410002:'金融業務',
    100756: '理财',
    100219: '活动',
    101154: '认购历史',




    122000:"全球DAO分布和社媒鏈接",
    122001:"邀請好友獲取獎勵",
    122002:"SuperEx的發展規劃",
    122003:"解決使用中遇見的常見問題",
    122004:"完成任務獲取獎勵",
    122005:"ET的介紹",
    122006:"SuperEx的介紹",
    122007:"代理商推薦返傭",
    122008:"低手續費購買加密貨幣",
    122009:"便捷購買加密貨幣",
    122010:"加密貨幣交易市場",
    122011:"自由区和主板区代币交易",
    122012:"合約交易市場",
    122013:"高價值項目的IEO平台",
    122014:"存錢理財賺取收益",
    122015:"超低價購物",
    122016:"精彩活動聚集地",
    122017:"搶先佈局BTC生態新幣",
    122018:"SCS空投任務中心",
    122019:"SCS Chain官網",
    122020:"SCS Chain瀏覽器",
    122021:"SCS Chain測試網瀏覽器",
    122022:"開發人員文檔",
    122023:"瀏覽最新消息",
    122024:"快速瞭解最新事件概況",
    122025:"在YouTube上查看SuperEx",
    122026:"學習關於區塊鏈的一切",
    122027:"與朋友一起驚喜開盒",
    122028: '盲盒獎勵',

    8100000: '用户反馈',
    8000153: '自由上币',
    8000154: 'BTC协议市场',
    6000072: '现货自由区订单',
    6000073: '现货自由区账户',


    //合约积分
    9100007: '交易积分',
    9100031: '交易积分活动中心',

    9100032: '量化基金',
    9100033: '专业财富管理',

    //api key
    9100162: 'API 管理',
    8100013: '官方验证通道',
    8100014: '出售渠道',
    'target_1': '现货账户',
    'target_2': 'P2P账户',
    'target_3': '币本位合约账户',
    'target_4': 'U本位合约账户',
    'target_5': '超级合约账户',
    'target_6': '金融-可用利息账户',

    ...zhLocal,
    ...ac_zh,
    ...zhPoer
}
export default zh;

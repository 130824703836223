const kr = {
  7000001: "그리드",
  7000002: "그리드 전략",
  7000003: "현물 그리드",
  7000004: "변동성 시장",
  7000005: "저가 매수 고가 매도",
  7000006: "순환 차익 거래",
  7000007: "도구",

  target_8: "전략 계좌",
};
export default kr;

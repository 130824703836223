import ptLocal from 'element-ui/lib/locale/lang/pt'
import ac_pt from "./activity/ac_pt";
import ptPoer from "./languagePoer/pt";
const pt = {
    400000: 'Academia',
    400001: 'Notícias',
    400002: 'Dados',
    400003: 'Vídeos',
    400004: 'Academia',
    400005: 'Iniciar sessão',
    400006: 'Registrar',
    400007: 'Carteiras',
    400008: 'Visão geral da carteira',
    400009: 'Conta do Mercado Principal',

    400010: 'Conta P2P',
    400011: 'Conta F.',
    400012: 'Histórico da Carteira',
    400013: 'Ordens do Mercado Principal',
    400014: 'Ordens Futuros',
    400015: 'Visão Geral',
    400016: 'Verificação de Identidade',
    400017: 'Segurança da Conta',
    400018: 'Recompensa',
    400019: 'Preferências',

    400020: 'Centro de Vouchers',
    400021: 'Sair',
    400022: '<em class="normal">{num}</em> Notificações pendentes',
    400023: 'Limpar tudo',
    400024: 'Ver Mais',
    400025: 'Nenhum registro encontrado',
    400026: 'Escaneie para baixar o App',
    400027: 'Mais Opções de Download',
    400028: 'Detalhes da Notificação',
    400029: 'Entendi',

    400030: 'P2P',
    400031: 'Mercados',
    400032: 'À vista',
    400033: 'Futuros',
    400034: 'Super Start',
    400035: 'Endereço não existe',
    400036: 'A plataforma de negociação de ativos digitais mais segura do mundo!',
    400037: 'Sobre Nós',
    400038: 'Termos',
    400039: 'Privacidade',

    400040: 'Announcements',
    400041: 'Academia',
    400042: 'Centro de Iniciantes',
    400043: 'Negociação Spot',
    400044: 'Futuros',
    400045: 'FAQ',
    400046: 'Serviço',
    400047: 'Taxa',
    400048: 'Regras',
    400049: 'Aplicação de Listagem',

    400050: 'Reembolso',
    400051: 'Fale Conosco',
    400052: 'Centro de Ajuda',
    400053: 'Enviar Solicitação',
    400054: 'Contatos Comerciais',
    400055: 'Cooperação Institucional',
    400056: 'Idioma e Região',
    400057: 'Taxa de câmbio',
    400058: 'Selecionar idioma e região',
    400059: 'Selecionar taxa de câmbio',

    400060: 'Transferir',
    400061: 'Transferências internas do SuperEx são gratuitas.',
    400062: 'De',
    400063: 'a',
    400064: 'Par.',
    400065: 'Desconto de Taxa',
    400066: 'Cruzado',
    400067: 'Isolado',
    400068: 'Divisão',
    400069: 'Saldo',

    400070: 'Moeda digital',
    400071: 'Pesquisar',
    400072: 'Quantidade',
    400073: 'Disponível',
    400074: 'Tudo',
    400075: 'Confirmar Transferência',
    400076: 'Abrir',
    400077: 'Tente mais tarde',
    400078: 'Abrir Conta de Futuros',
    400079: 'Você ainda não abriu uma conta de futuros, portanto a transferência está temporariamente indisponível.',

    400080: 'Reenviar em {timeout}s',
    400081: 'Enviar código',
    400082: 'Todas as criptomoedas',
    400083: 'Todas as moedas fiat',
    400084: 'Todos os status',
    400085: 'Área Opcional',
    400086: 'Terceiro',
    400087: 'Diretriz',
    400088: 'Pedidos',
    400089: 'Centro de Mercantes P2P',

    400090: 'Central do Usuário P2P',
    400091: 'Método de recebimento',
    400092: 'Publicar novo anúncio',
    400093: 'Meus Anúncios',
    400094: 'Mais',
    400095: 'Aplicar',
    400096: 'Cancelar',
    400097: 'Você não pode postar anúncios temporariamente.',
    400098: 'Primeiro, aplique para se tornar um anunciante.',
    400099: 'Não verificado',

    400100: 'Não verificado',
    400101: 'Você não concluiu a verificação de identidade.',
    400102: 'Para a segurança da sua conta com outros usuários de negociação, por favor, complete primeiro a verificação de identidade.',
    400103: 'Definir apelido',
    400104: 'Defina o apelido da sua conta, recomenda-se não usar seu nome verdadeiro.',
    400105: 'Desculpe, você não pode se cadastrar como comerciante neste momento.',
    400106: 'Configuração bem-sucedida',
    400107: 'Vantagens do SuperEx P2P',
    400108: 'Compra sem taxa',
    400109: 'Negocie Bitcoin e outras criptomoedas de forma fácil e rápida, sem taxas de comissão para o lado do pedido, na SuperEx P2P!',

    400110: 'Métodos de Pagamento Flexíveis',
    400111: 'P2P permite que os vendedores escolham os métodos de pagamento livremente.',
    400112: 'Negocie a melhores preços',
    400113: 'SuperEx P2P permite que os usuários definam seus próprios preços e escolham o preço desejado para comprar e vender criptomoedas.',
    400114: 'Compre criptomoedas com segurança',
    400115: 'O SuperEx P2P adota o mecanismo de retirada de T+n, gerencia rigorosamente os comerciantes e filtra transações suspeitas com big data de controle de riscos para garantir a segurança dos ativos da plataforma, permitindo que os usuários realizem transações com segurança.',
    400116: '{amount} Volume',
    400117: '{num} %Taxa de Transação',
    400118: 'Preço',
    400119: 'Prazo para pagamento',

    400120: 'Método de pagamento do vendedor',
    400121: 'Termos de Transação',
    400122: 'Vou pagar',
    400123: 'Eu receberei',
    400124: 'Quero vender',
    400125: 'Método de Pagamento',
    400126: 'Configurar método de pagamento',
    400127: 'Adicionar Método de Pagamento',
    400128: 'Preço unitário de referência {price}',
    400129: 'Comprar',

    400130: 'Vender',
    400131: 'Para proteger a segurança dos fundos, haverá um limite de retirada {limit} para ativos comprados.',
    400132: 'Saiba mais',
    400133: 'Entendi',
    400134: 'De acordo com o país do seu documento e as normas da plataforma, por favor, troque para sua moeda local para transações.',
    400135: 'Limite de compra: {min}-{max} {fiat}',
    400136: 'Limite de venda: {min}-{max} {fiat}',
    400137: 'Anunciantes',
    400138: 'Limite / Quantidade',
    400139: 'Troca',

    400140: 'Quantidade',
    400141: 'Limite',
    400142: 'Processar',
    400143: 'OK',
    400144: 'Antes de começar a negociar, por favor, conclua a verificação de identidade.',
    400145: 'Seu número de cancelamentos de pedidos hoje excedeu {num} vezes, e você não pode continuar negociando. Tente novamente após {time}.',
    400146: 'Você atingiu o número máximo de ordens pendentes ({num}), por favor, complete a ordem em andamento primeiro.',
    400147: 'Este anunciante atingiu o número máximo de pedidos pendentes, por favor, tente mais tarde ou escolha outro anúncio.',
    400148: 'Você não atendeu aos requisitos do anunciante, tente outro anúncio.',
    400149: 'Selecionar',

    400150: 'Como funciona o P2P',
    400151: 'Comprar Cripto',
    400152: 'Vender Cripto',
    400153: 'Faça seu pedido',
    400154: 'Após o pedido, os ativos do vendedor para essa transação serão gerenciados pelo SuperEx P2P.',
    400155: 'Pagamento ao vendedor',
    400156: 'Envie dinheiro ao vendedor pelo método de pagamento nas informações da transação e clique em "Pago, Notificar Vendedor" no SuperEx P2P após a transferência.',
    400157: 'Obtenha sua criptomoeda',
    400158: 'Assim que o vendedor confirmar o recebimento do pagamento, a criptomoeda será liberada para você.',
    400159: 'Confirmar Pagamento',

    400160: 'Verifique o histórico de transações na sua conta e certifique-se de que recebeu o pagamento do comprador.',
    400161: 'Libere a criptomoeda',
    400162: 'Envie criptomoeda para os compradores no SuperEx P2P após confirmar o recebimento do pagamento.',
    400163: 'Valor',
    400164: 'Digite o valor',
    400165: 'Moeda fiduciária',
    400166: 'Todos os Métodos de Pagamento',
    400167: 'ID do Anúncio',
    400168: 'Confirmar',
    400169: 'Você tem certeza de que deseja remover o anúncio?',

    400170: '1. Usuários que removerem anúncios não poderão negociar com você por meio de anúncios.',
    400171: '2. A remoção de anúncios não afeta os pedidos em andamento.',
    400172: 'Você tem certeza de que deseja excluir este anúncio?',
    400173: 'Editar',
    400174: 'Excluir',
    400175: 'Listados',
    400176: 'Removido',
    400177: 'Data Criada',
    400178: 'Limite mínimo',
    400179: 'Valor Restante/Valor do Pedido',

    400180: 'Preço de Ordem',
    400181: 'Tipo',
    400182: 'Listagem Bem-sucedida',
    400183: 'Todos os tipos',
    400184: 'Status',
    400185: 'ID do Anúncio/Moeda/Data',
    400186: 'Operar',
    400187: 'De acordo com as especificações operacionais do país ou região onde sua identidade SuperEx é certificada e da plataforma, escolha a moeda fiat suportada para a transação.',
    400188: 'Regras de publicidade não atendidas',
    400189: 'Excluído',

    400190: 'Removido com sucesso',
    400191: 'Publicar Anúncios',
    400192: 'Editar Anúncio',
    400193: 'Preço Fixo',
    400194: 'Preço Flutuante',
    400195: 'Porcentagem de Variação de Preço',
    400196: 'Digite',
    400197: 'Preço',
    400198: 'Preço de Referência do Mercado',
    400199: 'Definir um percentual flutuante permite comprar e vender a uma porcentagem acima ou abaixo do preço de referência do mercado.',

    400200: 'Os preços fixos não mudam com as flutuações do mercado.',
    400201: 'Restrições de Negociação',
    400202: 'Digite o volume da transação',
    400203: 'Número de negociações',
    400204: 'Limite mínimo por transação',
    400205: 'Digite o limite mínimo de transação única',
    400206: 'Limite único máximo',
    400207: 'Insira o limite máximo de transação única',
    400208: 'Máx',
    400209: 'O anúncio requer uma taxa de cerca de {rate}% {currency}, atualmente disponível {available} {currency}',

    400210: 'Método de pagamento',
    400211: 'Selecione até 3',
    400212: 'Sem método de pagamento no momento',
    400213: 'Escolha o prazo de pagamento',
    400214: 'Digite o conteúdo',
    400215: 'Condições do Usuário para Negociação',
    400216: 'Aumentar o limite de usuários de negociação reduzirá as chances de seu anúncio ser exibido.',
    400217: 'Completar registro',
    400218: 'Depois de dias',
    400219: 'Lançar Agora',

    400220: 'Lançar Depois',
    400221: 'Aviso de Risco',
    400222: 'Você deve inserir um número',
    400223: 'Escolha o método de pagamento',
    400224: 'Digite o preço flutuante',
    400225: 'Digite o preço fixo',
    400226: 'O preço se desviou do mercado em {num}%. Continuar a publicar será arriscado, por favor, confirme se deseja continuar a publicar anúncios.',
    400227: 'O limite máximo deve ser maior que o limite mínimo.',
    400228: 'O limite máximo por transação não deve ser superior a {num} {fiat}.',
    400229: 'O limite mínimo de transação única não pode ser inferior a {num} {fiat}',

    400230: 'O limite mínimo de pedido único deve ser menor que o limite máximo de pedido único.',
    400231: 'O volume máximo de transações não pode ser maior que {num} {currency}',
    400232: 'Publicação bem-sucedida',
    400233: 'Negociação P2P não está disponível para usuários de carteira autorizados neste momento. Faça login com sua conta SuperEx para realizar negociações P2P.',
    400234: 'Rede DAO',
    400235: 'Roadmap',
    400236: 'Convidar Amigos',
    400237: 'Centro de Ajuda',
    400238: 'Base de Airdrop',
    400239: 'Explorador da Mainnet',

    400240: 'Explorador Testnet',
    400241: 'Documentação do desenvolvedor',
    400242: 'Centro de Eventos',
    400243: 'Ao usar os sites da SuperEx e/ou manter sua conta, você concorda com nossos Termos de Serviço, Declaração de Privacidade e Política de Cookies atualizados. Ao clicar em "X", você reconhece que leu e concordou com os Termos e Políticas acima mencionados.',
    400244: 'Buy Tether | Get USDT | SuperEx P2P',
    400245: 'Centro de Tarefas',
    400246: 'Moeda',
    400247: 'Receber',
    400248: 'Despesas',
    400249: 'Disponível:',

    400250: 'Pagar',
    400251: 'Detalhes do pedido',
    400252: 'Montante (incluindo taxas de transação)',
    400253: 'Hora de chegada',
    400254: '≈{time}minutos',
    400255: 'Dicas',
    400256: '1. Você sairá do SuperEx e será levado à página da terceira parte {name} para completar seu pedido.',
    400257: '2. Após concluir o pedido, você pode acessar seus <span class="pointer">registros financeiros</span> para ver os detalhes.',
    400258: '3. Se você precisar de ajuda com o pagamento, entre em contato com o <span class="pointer">suporte ao cliente</span>do provedor de pagamento.',
    400259: '4. Consulte o FAQ para mais detalhes. <span class="pointer">FAQ</span> ',

    400260: 'Li e concordo com o aviso de isenção.',
    400261: 'Como comprar cripto na SuperEx?',
    400262: 'Como Vender Criptomoeda no SuperEx？',
    400263: 'Faça seu pedido de cripto',
    400264: 'Escolha a moeda fiduciária que deseja usar e a criptomoeda que quer comprar. Insira o valor que deseja gastar.',
    400265: 'Verifique sua identidade',
    400266: 'Complete a verificação KYC no SuperEx e no provedor de pagamentos para finalizar o pagamento.',
    400267: 'Verifique os detalhes do seu pedido',
    400268: 'Verifique seu histórico de depósitos para ver o status do seu pedido.',
    400269: '1. Quais métodos de pagamento posso usar para comprar criptomoeda?',

    400270: 'A SuperEx atualmente suporta vários métodos de pagamento, incluindo VISA, Mastercard, Apple Pay e Google Pay. Os provedores de serviços de terceiros suportados incluem Mercuryo e alchemypay.',
    400271: '2. Quais criptomoedas posso comprar?',
    400272: 'SuperEx supports mainstream cryptocurrencies such as BTC, ETH, USDT, LTC, EOS, XRP, BCH, ETC, and TRX.',
    400273: '3. A SuperEx monitora a qualidade e os preços dos provedores de serviços de terceiros suportados?',
    400274: 'A SuperEx selecionou cuidadosamente uma série de prestadores de serviços com boa reputação, popularidade e segurança no setor, para oferecer aos usuários serviços processuais.',
    400275: '4. Quais taxas preciso pagar para comprar criptomoeda?',
    400276: 'Você paga uma taxa ao provedor de serviços terceirizado e uma taxa de transferência on-chain. A SuperEx não cobra taxas.',
    400277: '5. Quanto tempo leva para receber a criptomoeda após o pagamento?',
    400278: 'Após a conclusão do pagamento na plataforma do prestador de serviços de terceiros, sua criptomoeda será depositada na sua conta à vista na SuperEx em cerca de 2 a 10 minutos.',
    400279: '6. O que fazer se eu encontrar problemas durante a compra?',

    400280: 'Entre em contato com o suporte ao cliente se você encontrar algum problema durante o processo de transação. Se você não recebeu a criptomoeda após o pagamento ser concluído, entre em contato com o prestador de serviços de terceiros para verificar os detalhes do pedido (geralmente este é o método mais eficiente). Devido ao IP da sua região atual ou a certas razões de política, você terá que escolher a verificação humana.',
    400281: 'Limite de pagamento único:',
    400282: 'Digite o volume',
    400283: 'Canal de pagamento',
    400284: 'Obter',
    400285: 'Saldo insuficiente',
    400286: "Você sairá do SuperEx e será redirecionado para <span>{name}</span>, uma instituição financeira licenciada que fornece capacidades de pagamento por cartão como terceiro. Quaisquer eventos de risco que ocorrerem durante o uso serão responsabilidade de <span>{name}</span>. Antes de usar os serviços de <span>{name}</span>, leia e concorde com o<a class= 'pointer' target='_blank' href={server}>Acordo de Serviço</a> dele. Se você tiver alguma dúvida sobre o depósito do cartão de crédito, entre em contato com o<a class='pointer' target='_blank' href={Customer}>Atendimento ao Cliente do {name}</a> para mais detalhes.",
    400287: 'Isenção de Responsabilidade',
    400289: 'Conta Spot Disponível',
    400290: 'Confirmar',
    400291: 'Zona ET',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: 'Comprar Cripto',

    400295:'Abra o Safari para usar o Apple Pay',
    400288: 'Finanças',

    400292:'Conta financeira',



    410000:'Airdrop SCS',
    410001:'Agentes',

    410002:'Finanças',
    100756: 'Renda',
    100219: 'Eventos',
    101154: 'Histórico de Assinatura',




    122000:"Rede Global de DAO e Links de Mídia Social",
    122001:"Convide amigos para ganhar recompensas",
    122002:"Plano de Desenvolvimento do SuperEx",
    122003:"Soluções para Problemas Comuns Encontrados Durante o Uso",
    122004:"Complete tarefas para ganhar recompensas",
    122005:"Introdução ao ET",
    122006:"Introdução ao SuperEx",
    122007:"Rebate para Indicação de Agente",
    122008:"Compra de criptomoeda com taxa baixa",
    122009:"Compra Rápida de Criptomoedas",
    122010:"Mercado de Criptoativos",
    122011:"Mercado Livre & Mercado Principal",
    122012:"Mercado de Futuros",
    122013:"Plataforma IEO para Projetos de Alto Valor",
    122014:"Economizar e investir para obter rendimentos",
    122015:"Compras a Preços Ultrabaixos",
    122016:"Centro de Atividades Empolgantes",
    122017:"Antecipe-se no ecossistema BTC com novas moedas",
    122018:"Centro de Tarefas SCS Airdrop",
    122019:"Site Oficial do SCS Chain",
    122020:"Navegador SCS Chain",
    122021:"Navegador da Testnet SCS Chain",
    122022:"Documentação do Desenvolvedor",
    122023:"Navegue pelas últimas notícias",
    122024:"Obtenha uma visão rápida dos últimos eventos",
    122025:"Ver SuperEx no YouTube",
    122026:"Aprenda tudo sobre blockchain",
    122027:"Abra a caixa surpresa com seus amigos",
    122028: 'Recompensa Caixa Mistério',

    8100000: 'Feedback do Usuário',
    8000153: 'Adicionar Tokens',
    8000154: 'Mercados do Protocolo BTC',
    6000072: 'Ordens de Mercado Livre',
    6000073: 'Conta de Mercado Livre',


    //合约积分
    9100007: 'Pontos de Negócio',
    9100031: 'Centro de Atividades de Pontos de Troca',

    9100032: 'Fundos Quantitativos',
    9100033: 'Gestão Profissional de Ativos',

    //api key
    9100162: 'Gestão de API',
    8100013: 'Canal de Verificação Oficial',
    8100014: 'Canal de venda',
    'target_1': 'Conta',
    'target_2': 'Conta P2P',
    'target_3': '币本位合约账户',
    'target_4': 'Conta USD',
    'target_5': '超级合约账户',
    'target_6': 'Finanças - Juros Disponíveis',

    ...ptLocal,
    ...ac_pt,
    ...ptPoer
}
export default pt;

const zh_Hk = {
  7000001: "網格",
  7000002: "網格策略",
  7000003: "現貨網格",
  7000004: "震盪行情",
  7000005: "低買高賣",
  7000006: "循環套利",
  7000007: "工具",

  target_8: "策略帳戶",
};
export default zh_Hk;

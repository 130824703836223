<!--
 * @Description: 二级导航组件
 * @Author: F-Stone
 * @LastEditTime: 2023-10-12 18:02:30
-->

<template>
    <div
        :class="[$style['site-nav--sub'], headType ? $style['site-nav'] : '', styleType == 'sunlight' ? $style['themSunlight'] : 'themDay']">
        <div :class="$style['site-nav--sub-inner']" class="grid">
            <div v-if="type" :class="$style['nav-sub-item']" style="padding: 0 0 0 12px;">
                <div :class="$style['item-title']">
                    <span :class="$style['text']" v-if="userInfo && userInfo.type != 3">
                        {{ userInfo && userInfo.email ? emailShow(userInfo.email) : telephoneShow(userInfo.phone) }}
                    </span>
                    <span :class="$style['text']" v-else>
                        {{ ellipseAddress(walletAddress) }}
                    </span>
                </div>
            </div>
            <div :class="$style['level']" v-if="type">
                <span :class="$style['inline-block']" v-if="levelConfig">
                    <svg :class="$style['icon']" aria-hidden="true">
                        <use xlink:href="#icon-VIP1" />
                    </svg>
                    {{ levelConfig.level | levelFilter }}
                </span>
            </div>
            <div v-if="tradeType" :class="$style['tradeType']">
                {{$t('400139')}}
            </div>
            <div v-for="(subItem, subIndex) in subNavList" :key="subIndex" v-if="!subItem.isShow">
                <div :class="$style['trade_tool']" v-if="subItem.tradeGroud">
                    <!-- 现货网格区分分类 -->
                    <div :class="$style['trade_toolTxt']">
                        {{$t('7000007')}}  
                    </div>
                </div>
                <div :class="[$style['nav-sub-item'], include(subItem) ? $style['navActive'] : '']">
                    <a :class="$style['item-inner']" @click="toLink(subItem)" class="grid">
                        <div :class="$style['item-icon']" v-if="subItem.icon">
                            <!-- <UeIcon :name="subItem.icon" /> -->
                            <Exicon :name="subItem.icon" :size="24" />
                        </div>
                        <div :class="$style['item-info']">
                            <div :class="$style['item-title']">
                                <span :class="$style['text']">{{ subItem.title }}</span>
                            </div>
                            <div :class="$style['item-desc']" v-if="subItem.desc">
                                <span :class="$style['text']">{{ subItem.desc }}</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script >
import mitt from "mitt";
import $ from 'jquery';
import { mapGetters } from 'vuex'
import { checkIsAgentState } from '@/apis/agent.js'
import { videoApi } from '@/apis/resource'
import { getC2cUserInfo } from '@/apis/otc.js'
import { getToken ,setCookie} from '@/utils/common'
import {
    telephoneShow,
    emailShow,
    parseTime,
    ellipseAddress,
} from '@/plugins/methods'
import urls from '@/utils/baseUrl'
export default {
    props: {
        distance: {
            type: String,
            default: ""
        },
        subNavList: {
            type: Array,
            default: []
        },
        width: {
            type: String,
            default: "310px"
        },
        type: {
            type: Boolean,
            default: false
        },
        headType: {
            type: Boolean,
            default: false
        },
        tradeType:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            c2cUserInfo: {},
            linkUrl: urls.linkUrl,
        }
    },
    filters: {
        levelFilter(val) {
            return 'VIP ' + val
        },
    },
    computed: {
        ...mapGetters([
            'chainConfig',
            'look',
            'currency',
            'languageName',
            'token',
            'userInfo',
            'levelConfig',
            'styleType',
            'language',
            'isDirection',
            'walletAddress',
            'walletChainId',
        ]),
    },
    methods: {
        include(data) {
            if(!data.name) return
            if (data.name === this.$route.name && !this.$route.query.btcStartup) {
                return true
            }
            return false
        },
        toLink(data) {
            // location跳转
            if (data.type === 1) {
                if(data.title == '1USD'){
                    window.gtag("event", "1 USD_click", {button:'1 USD'});
                }else if(data.title == this.$t('100756')){
                    window.gtag("event", "finance_click", {button:'finance'});
                }else if(data.title == this.$t('101482')){
                    window.gtag("event", "ET Zone_click", {button:'ET Zone'});
                }else if (data.title == this.$t('7000003')){
                    setCookie('gridType',"grid")
                }else if (data.title == this.$t('400032')){
                    setCookie('gridType',"trade")
                }
                window.location.href = this.linkUrl + data.link
                return
            }
            // Open跳转
            if (data.type === 2) {
                window.open(data.link)
                return
            }
            //站内跳转
            if (data.type === 3) {
                if(data.title == 'P2P'){
                    window.gtag("event", "p2p_select", {'third party_p2p_button':'p2p'});
                }else if(data.title == this.$t('101253')){
                    window.gtag("event", "p2p_select", {'third party_p2p_button':'third party'});
                }
                this.$router.push(data.link)
                // window.location.href = data.link
                return
            }
            // 代理商的跳转
            if (data.type === 4) {
                this.goAgent()
                return
            }
            // 学院视频的跳转
            if (data.type === 5) {
                this.getVideo()
                return
            }
            // 身份认证跳转
            if (data.type === 6) {
                if (this.token) {
                    this.getC2cUserInfo()
                }
                return
            }
            // 钱包断开连接
            if (data.type === 7) {
                this.$parent.handleWalletLogout()
            }
            // 账号密码退出登录
            if (data.type === 8) {
                this.$store.dispatch('LogOut', getToken() ? getToken() : '')
                this.$route.path == '/thirdParty' ? this.$router.push('/thirdParty') : this.$router.push('/')
                sessionStorage.removeItem('isTrue')
            }
        },
        // 手机号处理
        telephoneShow(phone) {
            return phone ? telephoneShow(phone) : ''
        },
        // 邮箱地址处理
        emailShow(email) {
            return email ? emailShow(email) : ''
        },
        // 钱包地址处理
        ellipseAddress(str) {
            return ellipseAddress(str)
        },
        // 身份认证
        getC2cUserInfo() {
            getC2cUserInfo().then((res) => {
                this.c2cUserInfo = res.data
                let Url = this.c2cUserInfo.isAuth ? '/userCenter/complete' : '/userCenter/identity'
                window.location.href = this.linkUrl + Url
            })
        },
        // 代理商
        goAgent() {
            window.gtag('event','agent_click',{button:'agent'})
            if (!this.token) {
                window.location.href = this.linkUrl + '/agentSystem/agentHome'
                return
            }
            checkIsAgentState()
                .then((res) => {
                    this.agentStatus = res.data.status
                    // this.agentCode = res.code
                    console.log(res, '200了哦')
                    if (this.agentStatus === 1) {
                        window.location.href = this.linkUrl + '/agentSystem/channel'
                        // this.$router.push('/agentSystem/channel')
                    } else {
                        window.location.href = this.linkUrl + '/agentSystem/agentHome'
                        // this.$router.push('/agentSystem/agentHome')
                    }
                })
                .catch((err) => {
                    console.log(err.code, '返回了500哦')
                    window.location.href = this.linkUrl + '/agentSystem/agentHome'
                    // this.$router.push('/agentSystem/agentHome')
                })
        },
        // 视频
        getVideo() {
            videoApi().then((res) => {
                const videoLink = res.data
                if (videoLink) {
                    window.open(videoLink)
                } else {
                    this.$notify({
                        message: this.$t('101114'), // 该地址不存在
                        iconClass: 'iconfont icon-cuowu1',
                        customClass: 'errorIcon flex-center',
                        showClose: false,
                        duration: 2000,
                        position: this.IsShow() ? 'top-left' : 'top-right',
                    })
                }
            })
        },
    },
};
</script>
<style lang="scss" module>
@import "../../assets/css/uemo-scss/scss/abstracts/mixins";
@import '../../assets/css/variable.scss';
@import '../../assets/css/mixin';

[dir='ltr'] .site-nav--sub {}

[dir='rtl'] .site-nav--sub {
    .item-title {
        text-align: right;
    }

    .item-desc {
        text-align: right;
    }
}

.site-nav--sub {
    --width: v-bind("$props.width");
    --nav-sub--inner-y: 10px;
    --opacity: 0;
    position: absolute;
    top: 100%;
    left: calc(50% - var(--width) / 2);
    visibility: hidden;
    padding-top: v-bind("$props.distance");
    transition: 0.26s ease;
    pointer-events: none;
}

.site-nav--sub-inner {
    position: relative;
    top: var(--nav-sub--inner-y);
    width: var(--width);
    padding: 20px 20px;
    transition: 0.26s ease;
    opacity: var(--opacity);
    border: 1px solid rgba(234, 236, 240, 0.2);
    border-radius: 12px;
    background: rgba(22, 23, 25, 0.8);
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
        0 12px 16px -4px rgba(16, 24, 40, 0.08);

    backdrop-filter: blur(25px);

    gap: 8px;
}

.level {
    border: 1px solid $border-color-active;
    width: 54px;
    height: 20px;
    text-align: center;
    border-radius: 2px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 5px;

    span {
        font-size: 12px;
        color: $font-active-color;

        .icon {
            width: 13px;
            height: 12px;
            vertical-align: -1px;
        }
    }
}
.tradeType{
    padding: 0 12px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
}
.trade_tool{
    padding: 0 12px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    .trade_toolTxt{
        padding-top: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
    }
}

.site-nav {
    &.themSunlight {
        .site-nav--sub-inner {
            background-color: rgba(255, 255, 255, 0.8);
        }

        .nav-sub-item {
            @supports (background-clip: text) {
                .item-icon {
                    background: linear-gradient(90deg,
                            #D88303 0%,
                            #F5B400 50%,
                            rgba(#7F8490, 0.6) 50%);
                }

                .item-title .text {
                    background: linear-gradient(90deg,
                            #D88303 0%,
                            #F5B400 50%,
                            #000922 50%);
                }

                .item-icon,
                .item-title .text {
                    transition: 0.16s ease;

                    background-position: 100% 0;
                    background-clip: text;
                    background-size: 200% 100%;

                    -webkit-text-fill-color: transparent;
                }

                &:hover {
                    @include background_color('background-color-15');

                    .item-title .text {
                        transition-delay: 0.08s;

                        background-position: 0 0;
                    }

                    .item-icon {
                        transition-delay: 0s;

                        background-position: 0 0;
                    }
                }
            }

            @supports not (background-clip: text) {

                .item-icon,
                .item-title .text {
                    transition: 0.16s ease;
                }

                &:hover {

                    .item-title .text,
                    .item-icon {
                        color: rgba(var(--g--color-theme--main), 1);
                    }
                }
            }

            padding: 12px;

            .item-inner {
                gap: 16px;
                grid-template-columns: auto 1fr;
            }

            .item-icon {
                font-size: 24px;

                transition-delay: 0.08s;
            }

            .item-title {
                font-size: 14px;
                font-weight: 600;
                // line-height: 1.6;
                white-space: nowrap;
                text-align: left;
            }

            .item-desc {
                font-size: 12px;
                line-height: 1.4;
                margin-top: 4px;
                color: #40475a;
                white-space: pre-wrap;
                text-align: left;
            }
        }

        .navActive {
            @include background_color('background-color-15');

            @supports (background-clip: text) {
                .item-icon {
                    background: linear-gradient(90deg,
                            #D88303 0%,
                            #F5B400 50%,
                            rgba(#fff, 0.6) 50%) !important;
                }

                .item-title .text {
                    background: linear-gradient(90deg,
                            #D88303 0%,
                            #F5B400 50%,
                            #fff 50%) !important;
                }

                .item-icon,
                .item-title .text {
                    transition: 0.16s ease !important;
                    background-position: 100% 0 !important;
                    background-clip: text !important;
                    background-size: 200% 100% !important;
                    -webkit-text-fill-color: transparent !important;
                    transition-delay: 0.08s !important;
                    background-position: 0 0 !important;
                }
            }
        }
    }
}

.nav-sub-item {
    @supports (background-clip: text) {
        .item-icon {
            background: linear-gradient(90deg,
                    rgba(var(--g--color-theme--main), 1) 0%,
                    #ffde68 50%,
                    rgba(#fff, 0.6) 50%);
        }

        .item-title .text {
            background: linear-gradient(90deg,
                    rgba(var(--g--color-theme--main), 1) 0%,
                    #ffde68 50%,
                    #fff 50%);
        }

        .item-icon,
        .item-title .text {
            transition: 0.16s ease;

            background-position: 100% 0;
            background-clip: text;
            background-size: 200% 100%;

            -webkit-text-fill-color: transparent;
        }

        border-radius: 8px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.05);

            .item-title .text {
                transition-delay: 0.08s;

                background-position: 0 0;
            }

            .item-icon {
                transition-delay: 0s;

                background-position: 0 0;
            }
        }
    }

    @supports not (background-clip: text) {

        .item-icon,
        .item-title .text {
            transition: 0.16s ease;
        }

        &:hover {

            .item-title .text,
            .item-icon {
                color: rgba(var(--g--color-theme--main), 1);
            }
        }
    }

    padding: 12px;

    .item-inner {
        gap: 16px;
        grid-template-columns: auto 1fr;
    }

    .item-icon {
        font-size: 24px;

        transition-delay: 0.08s;
    }

    .item-title {
        font-size: 14px !important;
        font-weight: 600;
        // line-height: 1.6;
        white-space: nowrap;
        text-align: left;
    }

    .item-desc {
        font-size: 12px !important;
        line-height: 1.4;
        margin-top: 4px;
        color: rgba(255, 255, 255, 0.7);
        white-space: pre-wrap;
        text-align: left;
    }
}

.navActive {
    @include background_color('background-color-15');

    @supports (background-clip: text) {
        .item-icon {
            background: linear-gradient(90deg,
                    rgba(var(--g--color-theme--main), 1) 0%,
                    #ffde68 50%,
                    rgba(#fff, 0.6) 50%) !important;
        }

        .item-title .text {
            background: linear-gradient(90deg,
                    rgba(var(--g--color-theme--main), 1) 0%,
                    #ffde68 50%,
                    #fff 50%) !important;
        }

        .item-icon,
        .item-title .text {
            transition: 0.16s ease !important;
            background-position: 100% 0 !important;
            background-clip: text !important;
            background-size: 200% 100% !important;
            -webkit-text-fill-color: transparent !important;
            transition-delay: 0.08s !important;
            background-position: 0 0 !important;
        }
    }
}

@media (max-width: 1600px) {

    // @-moz-document url-prefix() {
    .site-nav--sub-inner {
        padding: 15px 12px !important;
        gap: 4px !important;
        .item-icon{
            i{
                font-size: 16px !important;
            }
        }
        .item-title {
            font-size: 12px !important;
        }

        .item-desc {
            font-size: 10px !important;
        }
    }

    .nav-sub-item {
        padding: 8px !important;
    }
}</style>

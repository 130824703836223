const en = {
  7000001: "Grid",
  7000002: "Grid Strategy",
  7000003: "Spot Grid",
  7000004: "Oscillator",
  7000005: "Buy Low Sell High",
  7000006: "Cyclical Arbitrage",
  7000007: "Tool",

  target_8: "Strategy Account",
};
export default en;

const pt = {
    7000001: "Grade",
    7000002: "Estratégia Grid",
    7000003: "Grade Spot",
    7000004: "Oscilador",
    7000005: "Comprar baixo, vender alto",
    7000006: "Arbitragem Cíclica",
    7000007: "Ferramenta",
  
    target_8: "Conta de Estratégia",
  };
  export default pt;
  
const ru = {
  7000001: "Сетка",
  7000002: "Сеточная стратегия",
  7000003: "Сетка для спотовой торговли",
  7000004: "Колеблющийся рынок",
  7000005: "Покупка на спаде, продажа на подъеме",
  7000006: "Циклический арбитраж",
  7000007: "Инструмент",

  target_8: "Стратегический счет",
};
export default ru;

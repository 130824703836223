const fa = {
    7000001: "گرید",
    7000002: "استراتژی گرید",
    7000003: "گرید اسپات",
    7000004: "نوسان ساز",
    7000005: "خرید کم فروش بالا",
    7000006: "آربیتراژ چرخه ای",
    7000007: "ابزار",

    target_8: "حساب استراتژی",
}
export default fa
